import { Grid, Box } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Typography } from 'src/components/atoms/Typography'
import { FC } from 'react'

type Icontent = {
    level: string
    color: string
    text: string
    theme?: string
}
interface Props {
    name: string
    levels: string
    content: Icontent[]
    id: string
}

const useStyles = makeStyles((theme) => ({
  Box: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '30px 50px',
    [theme.breakpoints.down('sm')]: {
      padding: '30px 30px',
    },
  },
  container: {
    width: '77vw',
    padding: '30px 0',
    border: '3px solid #000',
    [theme.breakpoints.down('lg')]: {
      width: '90vw',
    },
    '& h3': {
      fontSize: 'calc(2em + 1vw)',
      fontWeight: 'bold',
      textAlign: 'center',
      padding: '10px calc(.5em + 1vw)',
      width: '100%',
      height: '2em',
      '& span': {
        background: '#808080',
        fontSize: 'calc(.7em + 1vw) ',
        fontWeight: 'bold',
        textAlign: 'center',
        color: '#fff',
      },
    },
    [theme.breakpoints.down('sm')]: {
      '& h3': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: '40px',
        '& span': {
          width: '12rem',
          fontSize: 'calc(.8em + 1vw) !important',
        },
      },
    },
  },
  item: {
    padding: '10px 20px',
    textAlign: 'center',
    '& h4': {
      fontSize: ({ name }:any) => (name === 'Modulos' ? 'calc(1em + 1vw)' : 'calc(2em + 1vw)'),
      fontWeight: 'bold',
    },
  },
  paragraph: {
    lineHeight: 'calc(1em + 1vw)',
    fontSize: 'calc(.6em + 1vw)',
    fontWeight: '300',
  },
  theme: {
    lineHeight: 'calc(1em + 1vw)',
    fontSize: 'calc(.6em + 1vw)',
    textAlign: 'center',
    fontWeight: '300',
  },
}))
export const LevelModules: FC<Props> = ({ name, levels, content }) => {
  const classes = useStyles({ name })
  return (
    <Box component="div" className={classes.Box}>
      <Grid container classes={{ root: classes.container }}>
        <Typography variant="h3">
          {levels}
          <span>{name}</span>
        </Typography>
        {
                    content.map(({ level, color, text, theme }) => (
                      <Grid key={level} item xl={4} lg={4} md={4} sm={6} xs={12} classes={{ root: classes.item }}>
                        <Typography sx={{ color }} variant="h4">{level}</Typography>
                        <Typography variant="body1" classes={{ root: classes.paragraph }}>{text}</Typography>
                        {theme && (
                        <Typography color="primary" variant="body1" classes={{ root: classes.theme }}>
                          (
                          {theme}
                          )
                        </Typography>
                        )}
                      </Grid>
                    ))
                }
      </Grid>
    </Box>
  )
}
