import { Box } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { FC } from 'react'
import { Typography } from 'src/components/atoms/Typography'

interface Props {
  learn: string[]
  tips?: string[]
}
const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    padding: '0 85px',
    [theme.breakpoints.only('xs')]: {
      padding: '0 30px',
    },
  },
  title: {
    textAlign: 'center',
    fontSize: 'calc(1.5em + 1vw)',
    margin: 'calc(0.5em + 1vh) 0',
    fontWeight: 'bold',
  },
  info: {
    padding: '10px 0',
    fontSize: 'calc(.5em + 1vw)',
    [theme.breakpoints.down('sm')]: {
      fontSize: 'calc(.7em + 1vw)',
    },
  },
}))
export const ParagraphSection: FC<Props> = ({ learn, tips }) => {
  const classes = useStyles()
  return (
    <Box component="div" className={classes.container}>
      <div>
        <Typography variant="h3" className={classes.title}>¿Porqué aprender con nosotros?</Typography>
        {
          learn.map((text, i) => (
            <Box component="div" key={i}>
              <Typography variant="body1" className={classes.info}>{text}</Typography>
              <br />
            </Box>
          ))
        }
      </div>

      <div>
        <Typography variant="h3" className={classes.title}>Consejos para el curso.</Typography>
        {
          tips &&
          tips.map((text, i) => (
            <Box key={i}>
              <Typography variant="body1" className={classes.info}>{text}</Typography>
              <br />
            </Box>
          ))
        }
      </div>
    </Box>
  )
}
